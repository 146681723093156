<template>
  <div id="import-accommodation-page">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col cols="12" sm="6" lg="4" xl="3">
              <b-form-group
                label="ID Allotjament Channel Manager"
                label-for="channelManagerId"
              >
                <b-form-input
                  id="channelManagerId"
                  v-model="channelManagerId"
                  placeholder="1846XXXX"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-2 d-flex justify-content-center justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="onCancelButtonClicked">
          Cancel·la
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="onImportButtonClicked">
          Importa
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";

export default {
  name: "ImportAccommodation",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      channelManagerId: null,
    };
  },
  methods: {
    onCancelButtonClicked() {
      this.$router.push({ name: "foravila-accommodations-list" });
    },
    onImportButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("accommodation/importAccommodation", this.channelManagerId)
        .then(() => this.$router.push({ name: "foravila-accommodations-list" }))
        .catch(() =>
          notifyError(
            "Allotjament no creat",
            "L'allotjament no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
